// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urlBase: {
    authority:
      'https://login.microsoftonline.com/3cffedfc-da13-4607-a160-06a25211238a',
    //AMBIENTE DESARROLLO
    mantenimientoRegion: 'https://api.dev.pangeaco.pe/bss/takerate/region/',
    mantenimientoZona: 'https://api.dev.pangeaco.pe/bss/takerate/sector/',
    maintenanceVno: 'https://api.dev.pangeaco.pe/bss/takerate/vno/',
    cargaArchivo: 'https://api.dev.pangeaco.pe/bss/takerate/price/',
    subirUICPToken: 'https://fnapeu2bssd06.azurewebsites.net/api/generatesastoken',
    subirUICPBlob: 'https://staceu2etlbackd04.blob.core.windows.net',
    clientId: '0f6d9b79-2804-4612-a6d9-e03778e739f3',
    redirectUri: 'https://billing.dev.pangeaco.pe',
    //AMBIENTE LOCAL
    // mantenimientoRegion: "/bss/takerate/region/",
    // mantenimientoZona: "/bss/takerate/sector/",
    // maintenanceVno: '/bss/takerate/vno/',
    // cargaArchivo: "/bss/takerate/price/",
    // clientId: '9b4431af-725f-4a84-92b0-e4df66153d03',
    // redirectUri: 'http://localhost:4200/home/reports',
    //AMBIENTE CERTIFICACION
    //mantenimientoRegion: "https://api.cer.pangeaco.pe/fnapeu2bssc01/",
    //mantenimientoZona: "https://api.cer.pangeaco.pe/fnapeu2bssc02/",
    //cargaArchivo: "https://api.cer.pangeaco.pe/fnapeu2bssc04/",
    //clientId: '40aa9534-ee27-4d9c-aed1-fdade3d21f1e',
    //redirectUri: 'https://billing.cer.pangeaco.pe'
    //AMBIENTE PRODUCCION
    //mantenimientoRegion: "https://api.pangeaco.pe/fnapeu2bssc01/",
    //mantenimientoZona: "https://api.pangeaco.pe/fnapeu2bssc02/",
    //cargaArchivo: "https://api.pangeaco.pe/fnapeu2bssc04/",
    //clientId: 'bb562acb-75e7-41fc-8730-a2eb4363de64',
    //redirectUri: 'https://billing.pangeaco.pe'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
